/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    width: 100%;
  }
  
  .slide {
    display: inline-block;
    height: auto;
    width: 100%;
  }
  
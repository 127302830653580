.vip-card {
    width: 90%;
    height: 80%;
    margin: auto;
    min-height: 512px;
    box-shadow: 0px 35px 70px 0px rgba(255, 163, 125, 0.65), 0px 30px 40px -10px rgba(255, 100, 124, 0.55);
    background: linear-gradient(180deg, rgba(255, 163, 125, 1) 0%, rgba(255, 99, 124, 1) 100%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width: 512px) {
    .vip-card {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }
  
  .vip-header {
    display: flex;
    padding: 48px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  
  .vip-logo {
    font-weight: bold;
    font-size: 1.5em;
    transition: opacity 0.05s ease;
  }
  
  .vip-logo:hover {
    opacity: 0.75;
  }
  
  .vip-social {
    display: flex;
  }
  
  .vip-social a {
    display: inline-block;
    margin-right: 12px;
    transition: opacity 0.05s ease;
  }
  
  .vip-social a:last-child {
    margin-right: 0;
  }
  
  .vip-social a:hover {
    opacity: 0.75;
  }
  
  .vip-social .icon {
    width: 18px;
    fill: #fff;
  }
  
  .vip-content {
    flex: 1 1 auto;
    min-height: 256px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .vip-content .title-holder {
    color: #fff;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .vip-content .title-holder h1 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 12px;
  }
  
  .vip-content .title-holder p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
  }
  
  @media screen and (max-width: 768px) {
    .vip-content .title-holder {
      max-width: 80%;
    }
  }
  
  .vip-content .cta {
    min-width: 64px;
    padding: 16px 24px;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    transform: none;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
  }
  
  .vip-content .cta:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
  }
  
  .vip-footer {
    display: flex;
    flex-direction: row;
    padding: 48px;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  
  a.vip-underlined:hover {
    border-bottom: 1px dotted #fff;
  }
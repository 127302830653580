.bonuses-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 5%;
    border-radius: 10px;
    background-color: #1c002e;
}
.bonuses-header{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bonuses-header h1{
    font-size: 8vw;
    font-weight: 400;
    letter-spacing: .05em;
    color: #fff;
    text-align: center;
}
.bonuses-content{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5%;
    color: #0078FF;
    margin-bottom: 15%;
}
.bonuses-content-box{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2.5% 0;
}
.bonuses-content-img{
    width: 100%;
}

.bonuses-content-box img{
    width: 100%;
    min-width: 100%;
    height: auto;
}
.bonuses-content-desc{
    width: 100%;
    height: auto;
}
.bonus-name{
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    padding: 3% 0;
}
.bonus-content-desc-buttons{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}
.bonus-content-register-button{
    width: 60%;
    padding: 3% 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 0;
    color: #000000;
    color: #ffd91d;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}
.bonus-content-desc-button{
    width: 40%;
    padding: 3% 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 0;
    color: #000000;
    color: #ffd91d;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}
.bonuses-popup{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(0, 0, 0);
    z-index: 100;
}
.bonuses-popup-header{
    width: 100%;
    height: 50px;
    background-color: #ad1ddb;
    color:rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.bonuses-popup-header-title{
    font-size: 20px;
    font-weight: 500;
}
.bonuses-popup-header-close{
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    font-size: 1.5em;
}
.bonuses-popup-header-close-line{
    width: 50%;
    height: 3px;
    background-color: #000;
    transform: rotate(45deg);
    position: absolute;
}
.bonuses-popup-header-close-line:nth-child(2){
    transform: rotate(-45deg);
}
.bonuses-popup-content{
    width: 100%;
    height: 95%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bonuses-popup-content p{
    width: 90%;
    text-align: left;
    font-size: 16px;
    white-space: pre-line;

}
.bonuses-popup-content h1{
    width: 90%;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
}
.bonuses-popupcan{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
@media (min-width: 1024px){
    .bonuses-main{
        padding: 0 7.5%;
    }
    .bonuses-header h1{
        font-size: 2.54vw;
    }
    .bonuses-content{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 2.5%;
    }
    .bonuses-content-img{
        padding: 3.45% 0;
    }
    .bonuses-content-img img{
        min-width: 92.5%;
        width: 92.5%;
    }
    .bonus-content-desc-buttons{
        height: 125px;
    }
    .bonus-content-desc-button{
        width: 60%;
        padding: 4% 0;
    }
    .bonuses-popup{
        z-index: 500!important;
        background-color: #0000007b;
        justify-content: center!important;
        align-items: center!important;
    }
    .bonuses-popupcan{
        width: 50%!important;
        height: 90%!important;
        background-color: #000;
        border-radius: 1.3em;
    }
    .bonuses-popup-header{
        border-top-left-radius: 1.3em;
        border-top-right-radius: 1.3em;
    }
}   
.PopularMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #1c002e;
  width: 100%;
  border-radius: 0.5rem;
  padding-bottom: 30px;
  margin-bottom: 100px;
}
.PopularTitle {
  color: white;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.GamesGrid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.PopularGames {
  width: 95%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 30% 30% 30%;
  gap: 10px;
}
.PopularGame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.PopularGames img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.PopularLink {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ad1ddb;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  width: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.hiddenGameInfo {
  display: none;
}
@media (min-width: 768px) {
  .PopularMain {
    width: 100%;
  }
  .PopularGames {
    display: flex;
    width: 80%;
    height: 100%;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .PopularGame {
    width: 80%;
    height: 100%;
    flex: 1 1 20%;
    padding: 10px 15px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
  }
  .PopularGames img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }
  .PopularLink {
    width: 30%;
  }
  .hiddenGameInfo {
    display: none;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }
  .hiddenGameInfo h3 {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .hiddenGameInfo p {
    color: white;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .PopularGame:hover .hiddenGameInfo {
    display: flex;
  }
  .hiddenGameInfo:hover {
    cursor: pointer;
  }
  .playBTN{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ad1ddb;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    width: 30%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.LoginPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 92vh;
    position: relative;
    z-index: 500;
}
.LoginMain{
    display: flex;
    flex-direction: column;
    position: absolute!important;
    z-index: 501;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-top: -8vh;
    background-color: #460073;
}
.LoginHeader{
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8%;
    font-size: .7rem;
    font-weight: 600;
}
.LoginHeaderLogo{
    width: auto;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;

}
.LoginHeaderTitle{
    margin: 0;
    margin-left: 10%;
    width: 90%;
    height: 4%;
    font-size: 1.2rem;
    padding: 0;
    display: flex;
    justify-content: flex;
    align-items: center;
}
.LoginHeaderClose{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.LoginHeaderLine{
    width: 60%;
    height: 4px;
    background-color: #ffffff;
    position: absolute
}
.LoginHeaderRight{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5%;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    text-decoration: none;
    border-radius: 15px;
}
.LoginHeaderRegister{
    width: 40%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
    margin-right: 5%;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #ad1ddb;
    text-decoration: none;
    border-radius: 15px;
}
.line1{
    transform: rotate(45deg);
}
.line2{
    transform: rotate(-45deg);
}
.LoginContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 70%;
}
.LoginForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 45%;
}
.LoginFormInput{
    width: calc(80% - 2rem);
    height: 2rem;
    display: flex;
    padding: .4rem 1rem;
    margin-top: 5%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
}
.LoginFormInput:focus{
    border: none;
    outline: none;
    border-inline: none;
}
.LoginFormButton{
    width: 80%;
    height: 2rem;
    padding: 1.2rem 0;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    background-color: #ad1ddb;
    color: white;
    border-radius: 8px;
}
.LoginFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    font-size: .9rem;
    font-weight: 600;
    color: #ffffff;
}
.LoginFooterLink{
    color: #ffd91d;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RememberMe{
    width: 80%;
    padding-top: 5%;
    display: flex;
    justify-content: flex-start;
}
.RememberMeInput{
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}
.LoginHeaderResponse{
    width: calc(80% - 2rem);
    height: calc(7.5% - 1rem);
    border-radius: 5px;
    display: none;
    justify-content: flex-start;
    padding: 1rem 1rem;
    align-items: center;
    font-size: .7rem;
    font-weight: 600;
    background-color: #FF0000;
    color: #ffffff;
    transition: all .5s;
}
.LoginBackground{
    position: absolute;
    z-index: 500;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: .5;
    display: none;
}
.LoginHeaderResponseActive{
    display: flex;
    transition: all .5s;
}
@media (min-width: 1024px){
    .LoginBackground{
        display: flex;
        z-index: 501;
        position: absolute;
        margin-top: -9vh;
    }
    .LoginPage{
        height: 100vh;
        width: 99.5vw;
        position: fixed;
    }
    .LoginMain{
        height: 70%;
        width: 30%;
        margin-top: 0;
        border-radius: 15px;
        transform: translate(0%, 10%);
    }
    .LoginHeader{
        height: 15%;
        font-size: 1rem;
    }
    .LoginHeaderLogo{
        height: 30%;
        margin-left: 7.5%;
    }
    .LoginHeaderTitle{
        height: 5%;
        font-size: 1.4vw;
    }
    .LoginHeaderClose{
        height: 100%;
        align-items: flex-end;
    }
    .LoginHeaderLine{
        height: 6px;
    }
    .LoginHeaderRight{
        font-size: 5vw;
        margin-right: 7.5%;
    }
    .LoginHeaderRegister{
        font-size: 1rem;
    }
    .LoginContent{
        padding: 0 7.5%;
        width: 85%;
        height: 100%;
        align-items: flex-start;
    }
    .LoginHeaderTitle{
        padding: 5% 0;
        margin-left: 0;
        width: 100%;
    }
    .LoginHeaderResponse{
        margin-left:0;
        width: 90%;
        padding: 3.5% 5%;
        font-size: 1.1rem;
    }
    .LoginForm{
        height: 60%;
    }
    .LoginFooter{
        font-size: 1rem;
        margin: 4% 0;
    }
    .LoginFormInput{
        width: calc(100% - 2rem);
        height: 12%;
        margin-top: 3.5%;
    }
    .LoginFormButton{
        width: 100%;
        height: calc(12% + 0.8rem);
        margin-top: 4.5%;
    }
    .RememberMe{
        padding-top: 5%;
        width: 100%;
    }
}
.RegisterPage {
    background-color: #460073;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: relative;
}
.RegisterPage .mainhome{
    min-height: 100%;
}
.RegisterPage .AdLine {
    display: none;
}

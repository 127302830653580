.mainnav{
    width: 100%;
    z-index:10;
}
.mainhome{
    background-color: #1c002e;
}
.profile-sidebar{
    width: 0%;
    display: none;
    background-color: #ad1ddb;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 101;
    transition: all 0.3s ease;
}

.profile-sidebar-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #460073;
    z-index: 100;
    transition: all 0.3s ease;
}

.header-flc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #460073;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  position: relative;
  height: 8vh;
  transition: all 0.3s ease;
}
.header-flc .header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  height: 65%;
}
.header-left {
  display: flex;
  align-items: center;
  height: 100%;
  width: 20%;
}
.header-logo{
    width: 100%;
    height: auto;
}
.casino-logo{
    color: #ffd91d;
    width: 15%;
    height: auto;
    padding: 0 3%;
}
.casino-logo:hover{
    color: #ffd91d;
    width: 15%;
    height: auto;
    padding: 0 3%;
}
.header-flc .header-main .header-left img {
  width: 100%;
  height: auto;
}
.header-middle{
    width: 35%;
    height: 100%;
}
.header-right{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.header-main .unlogged-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
}
.header-main .header-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 46%;
    height: 100%;
    font-size: .75em;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0.3em;
    text-transform: uppercase;
    
}
.header-main .header-btn:hover{
    color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 46%;
    height: 100%;
    font-size: .75em;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0.3em;
    text-transform: uppercase;
    
}
.header-main .login-btn{
    background-color: #ad1ddb;
    color: #f5f5f5;
    font-weight: 700;
    padding: 3 0%;
    height: 30px;
    width: 75px;
}
.header-main .login-btn:hover{
    background-color: #bf46e7;
    color: #f5f5f5;
    font-weight: 700;
    padding: 3 0%;
    height: 30px;
    width: 75px;
}
.header-main .register-btn{
    background-color: #f7db52;
    color: #ad1ddb;
    font-weight: 700;
    margin: 3%;
    padding: 3 3%;
    height: 30px;
    width: 75px;
}
.header-main .register-btn:hover{
    color: #ffd91d;
    color: #ad1ddb;
    font-weight: 700;
    margin: 3%;
    padding: 3 3%;
    height: 30px;
    width: 75px;
}
.header-main .header-middlelogged{
    width: 50%;
    padding-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
}
.header-main .header-rightlogged{
    width: 12%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70%;
}
.header-main .logged-buttons{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}
.header-main .balance-btn{
    background-color: white;
    color: #252525;
    font-size: .75em;
    cursor: pointer;
    border-radius: 0.3em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
}
.header-main .deposit-btn{
    background-color: #f5f5f5;
    color: #ad1ddb;
    font-size: .75em;
    cursor: pointer;
    border-radius: 0.3em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
}

.header-main .deposit-btn:hover{
    background-color: #f5f5f5;
    color: #ad1ddb;
    font-size: .75em;
    cursor: pointer;
    border-radius: 0.3em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
}
.header-main .logged-menubtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
}
.header-main .profile-btn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    width: 70%;
    height: 100%;
}
.header-main .straightline{
    height: 3px;
    border-radius: .1rem;
    width: 100%;
    background-color: #f5f5f5;
}
.profile-sidebar-header{
    width: 100%;
    height: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-sidebar-header-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.profile-sidebar-header-main-after{
    width: 90%;
    height: 3px;
    background-color: #f5f5f5;
    margin-top: 5px;
}

.profile-sidebar-header-main-main{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile-sidebar-header-title{
    font-size: 1em;
    color: #f5f5f5;
    margin-left: 5%;
}
.profile-sidebar-header-close{
    font-size: 1.5em;
    color: #f5f5f5;
    cursor: pointer;
    margin-right: 5%;
    width: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.close-line{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.profile-sidebar-header-close .straightline1{
    height: 3px;
    border-radius: .1rem;
    width: 40px;
    background-color: #f5f5f5;
    transform: rotate(45deg);
    position: absolute;
}
.profile-sidebar-header-close .straightline2{
    height: 3px;
    border-radius: .1rem;
    width: 40px;
    background-color: #f5f5f5;
    transform: rotate(-45deg);
    position: absolute;
}
.profile-sidebar-messages{
    width: 90%;
    height: 11%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    margin-left: 5%;
}
.profile-sidebar-messages-main{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 95%;
    justify-content: center;
    align-items: center;
}
.profile-sidebar-messages-main-title{
    font-size: .9em;
    width: 100%;
    height: 47.5%;
    color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.profile-sidebar-messages-main-messagebar{
    width: 100%;
    height: 47.5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.profile-sidebar-messages-main-messagetext{
    font-size: .75em;
    color: #f5f5f5;
}
.profile-sidebar-messages-after{
    width: 100%;
    height: 3px;
    margin-top: 5px;
    background-color: #f5f5f5;
}
.profile-sidebar-userinfo{
    width: 90%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
}
.profile-sidebar-userinfo-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}
.profile-sidebar-userinfo-balance{
    font-size: .9em;
    color: #f5f5f5;
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.balance-color{
    color: #ffd91d;
}
.profile-sidebar-userinfo-status{
    font-size: .75em;
    color: white;
    flex-direction: column;
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile-sidebar-userinfo-status-vip{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48%;
    font-size: 1em;
    background-color: #bf46e7;
    border-top-left-radius: .7rem;
    border-top-right-radius: .7rem;
}
.profile-sidebar-userinfo-status-freespin{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48%;
    font-size: 1em;
    background-color: #bf46e7;
    border-bottom-left-radius: .7rem;
    border-bottom-right-radius: .7rem;
}
.profile-sidebar-userinfo-status h3{
    color: #f5f5f5;
    margin-left: 5%;
    margin-right: 5%;
}
.profile-sidebar-menu{
    width: 100%;
    height: 58%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2%;
}
.activated {
    background-color: #000000;
    color: #0078FF;
}
.profile-sidebar-menu-button{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}
.profile-sidebar-menu-button h3{
    margin-left: 5%;
}
.profile-sidebar-menu-button-main{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}
.profile-sidebar-menu-button-main h3{
    color: #f5f5f5;
    font-size: 1.2em;
}
.profile-sidebar-menu-button-main span{
    color: #ffd91d;
    font-size: 1.4em;
    margin-right: 5%;
}
.profile-sidebar-menu-list{
    width: 100%;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease;
}
.activated span{
    transform: rotateZ(90deg);
}
.profile-sidebar-menu-list *{
    transition: all 0.3s ease;
}
.profile-sidebar-menu-list-main{
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}
.profile-sidebar-menu-list-item{
    width: 100%;
    height: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-decoration: none;

}
.twofive .profile-sidebar-menu-list-item{
    height: 25%;
}

.profile-sidebar-menu-list-item span{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10%;
    color: white;
    font-weight: bold;
    text-decoration: none;
    margin-left: 10%;
}
.profile-sidebar-menu-list-item::after{
    content: "";
    width: 100%;
    height: 1px;
    background-color: #2b2b2b;
    margin-top: 5px;
}
.header-middle{
    opacity: 0;
}
.header-middleleftlogged{
    display: none;
}
.mobile-bottomnavbar{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 8vh;
    background-color: #460073;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}
.mobile-bottomnavbar a{
    text-decoration: none;
    color: white;
    font-size: 1.3rem;
    transition: all 0.3s ease;
}
.mobile-bottomnavbarbutton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.mobile-bottomnavbarbutton img{
    margin-top: 10%;
    width: auto;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-bottomnavbarbutton h3{
    font-size: .6rem;
    height: 20%;
    width: 100%;
    margin-bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-sidebar{
    display: none;
    width: 0%;
    height: 100%;
    background-color: #460073;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
    transition: all 0.3s ease;
}
.menu-sidebar-overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    transition: all 0.3s ease;
}
.menu-sidebar-header{
    width: 100%;
    height: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-sidebar-header-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.menu-sidebar-header-main-main{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-sidebar-header-main-after{
    width: 90%;
    height: 3px;
    background-color: #f5f5f5;
    margin-top: 5px;
}
.menu-sidebar-header-title{
    font-size: 1em;
    color: #f5f5f5;
    margin-left: 5%;
}
.menu-sidebar-header-close{
    font-size: 1.5em;
    color: #f5f5f5;
    cursor: pointer;
    margin-right: 5%;
    width: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.menu-sidebar-header-close .straightline1{
    height: 3px;
    border-radius: .1rem;
    width: 40px;
    background-color: #f5f5f5;
    transform: rotate(45deg);
    position: absolute;
}
.menu-sidebar-header-close .straightline2{
    height: 3px;
    border-radius: .1rem;
    width: 40px;
    background-color: #f5f5f5;
    transform: rotate(-45deg);
    position: absolute;
}
.menu-sidebar-menu{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.menu-sidebar-menu-item{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}
.menu-sidebar-menu-item{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}
.menu-sidebar-menu-item h3{
    margin-left: 5%;
}
.menu-sidebar-menu-item-main{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}
.menu-sidebar-menu-item{
    color: #f5f5f5;
    font-size: 1.2em;
    margin-left: 10%;
    font-weight: bold;
}
@media (min-width: 1024px){
    .header-flc{
        height: 9vh;
    }
    .header-left{
        width: 10%;
    }
    .header-logo{
        width: 100%;
        height: auto;
    }
    .header-middle{
        width: 65%;
    }
    .header-right{
        width: 25%;
    }
    .header-rightlogged{
        width: 3%!important;
    }
    .header-middlelogged{
        width: 20%!important;
    }
    .header-middleleftlogged{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 67%;
        height: 100%;
    }
    .logged-buttons{
        font-size: 1.2rem;
    }
    .balance-btn{
        margin-right:1rem;
    }
    .deposit-btn{
        margin-right:1rem;
    }
    .profile-btn{
        width: 85%!important;
    }
    .pcnavbar{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 60%;
        height: 100%;
        font-size: 1.3rem;
        text-decoration: none;
    }
    .pcnavbar *{
        text-decoration: none;
        color: white;
        transition: all 0.3s ease;
    }
    .pcnavbar *:hover{
        color: #0078FF;
    }
    .profile-sidebar{
        width: 30%!important;
    }
    .mobile-bottomnavbar{
        display: none;
    }
}

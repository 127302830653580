.PlayGame{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84vh;
}
.PlayGameBlurBackground{
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 3;
}
.PlayGameBlurBackground img{
    width: 100%;
    height: 99%;
    object-fit: cover;
    filter: blur(5px);
}
.GameContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    height: 50%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.636);
    border-radius: 1em;

}
.GameImage{
    width: 85%;
    height: auto;
}
.GameContainer img{
    width: 100%;
    height: 100%;
}
.GameName{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 25%;
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffd91d;
}
.GamePlayButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 25%;
}
.GamePlayButton button{
    width: 70%;
    height: 50%;
    font-size: 1rem;
    font-weight: 600;
    color: #ffd91d;
    background-color: #ad1ddb;
    border: 1px solid #ad1ddb;
    border-radius: 10px;
    cursor: pointer;
}
a{
    text-decoration: none;
    color: #ffffff;
}
@media (min-width: 768px){
    .GameContainer{
        width: 30%;
        height: 70%;
    }
    .GameName{
        font-size: 2rem;
    }
    .GamePlayButton button{
        font-size: 1.2rem;
        width: 50%;
        height: 40%;
    }
    .GameImage{
        width: 60%;
    }
    .PlayGameBlurBackground{
        width: 100vw;
        height: 97vh;
    }
}
.App {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  height: 100vh;
  background-color: #1c002e;
  color: white;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
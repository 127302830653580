.AdMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}
.AdBanners{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}
.AdBanner{
    width: 47.5%;
    height: 100%;
}
.AdLine{
    width: 100%;
    height: 10px;
    background-color: #1c002e;
    margin-top: 15px;
}
@media (min-width: 1024px) {
    .AdBanners{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
    }
    .AdBanner{
        width: 47.5%;
        height: 100%;
    }
    .AdLine{
        width: 100%;
        height: 10px;
        background-color: #1c002e;
        margin-top: 15px;
    }
    #big{
        width: 74%;
        height: 100%;
    }
    #small{
        width: 25%;
        height: 100%;
    }
}
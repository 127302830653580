/* Slideshow */

.slideshows {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}

.slideshowSliders {
  white-space: nowrap;
  transition: ease 1000ms;
  width: 100%;
  position: relative;
}

.sliderButtonDiv {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
}
.sliderButton{
  background-color: #ad1ddb;
  padding: 10px 20px;
  border-radius: 5px;
  border: none; 
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
}

.slides {
  display: inline-block;
  height: auto;
  width: 100%;
}
.slider {
  display: inline-block;
  height: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .slides {
    height: auto;
  }
  .sliderButton{
    padding: 15px 30px;
    font-size: 17px;
  }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1c002e;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#status{
    width: 50%;
    height: 50%;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
#status > img {
    width: 70%;
    height: auto;
    top: 0;
    left: 0;
    animation: spin 2s infinite linear;
    margin-bottom: 3rem;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
#status > h3{
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    margin-top: 3rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
}
@media (min-width: 992px) {
    #status > h3{
        font-size: 20px!important;
    }
    #status > img {
        width: 15%!important;
    }

}
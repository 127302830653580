.mainhome{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #1c002e;
    color: white;
}
.maincontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    color: white;
}
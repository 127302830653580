.transaction-history-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #f7f7f7; /* Konteyner arkaplan rengi */
  }
  
  .transaction-table-title {
    color: #333; /* Başlık rengi */
  }
  
  .transaction-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transaction-table th,
  .transaction-table td {
      background-color: #ffd91d;
    padding: 10px;
    border: 1px solid #ffd91d;
    text-align: left;
  }
  
  .transaction-table th {
    background-color: #ad1ddb; /* Tablo başlık arkaplan rengi */
    border: 1px solid #ad1ddb;
    color: #fff; /* Tablo başlık metin rengi */
  }
  .transaction-container-fluid {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      background-color: #F7F7FF;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .transaction-container-title {
      width: 100%;
      height: 4vh;
      display: flex;
      justify-content: flex-start;
      background-color: #ad1ddb;
      align-items: center;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }
  .transaction-container-title h1 {
      font-size: 1.1em;
      color: #fff;
      text-align: center;
      width: 100%;
  }
  .transaction-container-fluid .container{
      width: 85%;
      height:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0;
      padding: 0;
  }
  .transaction-table-responsive{
      margin-top: 5%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
  }
  .transaction-container img {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin: 10% 0;
  }
  .transaction-card-img {
      width: 50%;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
  }
  .transaction-tablocan{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      padding: 2% 0;
  }
  .transaction-card-info{
      width: 45%;
      margin-left: 5%;
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
  }
  .transaction-card-info h4{
      color: #fff;
      font-size: 1em;
      margin: 0%;
      font-weight: lighter;
  }
  .transaction-card-info p{
      color: #ffd91d;
      font-size: 1em;
      margin: 0%;
      font-weight: lighter;
      letter-spacing: .05em;
  }
/* Bu kısım ödeme ekranının stilini içerir */
.payment-proccessor-card-body {
    flex: 1 1 auto;
    padding: 2rem 2rem !important;
}
.payment-proccessor-cards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #dddfeb;
    border-radius: .5rem;
}
.payment-proccessor-apscard {
    width: 100%;
    margin: auto;
}
.payment-proccessor-container-fluid {
    background-color: #f7f7f7;
  }
  
  .payment-proccessor-card-header {
    background-color: #fff;
  }
  
  .payment-proccessor-card-body {
    background-color: #fff;
  }
  
  .payment-proccessor-input-group {
    position: relative;
  }
  
  .payment-proccessor-clipboard {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  
  .payment-proccessor-btnYatirimiYaptim {
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 5px;
  }
  
.RegisterPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 92vh;
    position: relative;
    z-index: 500;
}
.RegisterMain2{
    display: flex;
    flex-direction: column;
    position: absolute!important;
    z-index: 501;
    justify-content: flex-start;
    align-items: center;
    height: 105vh;
    width: 100%;
    margin-top: -8vh;
    background-color: #460073;
}
.RegisterMain2 .RegisterHeader{
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8%;
    font-size: .7rem;
    font-weight: 600;
}
.RegisterMain2 .RegisterHeaderLogo{
    width: auto;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;

}
.RegisterMain2 .RegisterHeaderTitle{
    margin: 0;
    margin-left: 10%;
    width: 90%;
    height: 4%;
    font-size: 3vw;
    padding: 0;
    display: flex;
    justify-content: flex;
    align-items: center;
}
.RegisterMain2 .RegisterHeaderClose{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.RegisterMain2 .RegisterHeaderLine{
    width: 60%;
    height: 4px;
    background-color: #ffffff;
    position: absolute
}
.RegisterMain2 .RegisterHeaderRight{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5%;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    text-decoration: none;
    border-radius: 15px;
}
.RegisterMain2 .RegisterHeaderRegister{
    width: 40%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #ad1ddb;
    text-decoration: none;
    border-radius: 15px;
}
.RegisterMain2 .RegisterFormInput:disabled{
    background-color: #ffffff;
}
.disabled::placeholder{
    color: #000000;
}
.line1{
    transform: rotate(45deg);
}
.line2{
    transform: rotate(-45deg);
}
.RegisterMain2 .RegisterContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #460073;
}
.RegisterMain2 .RegisterForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}
.RegisterMain2 .RegisterFormInput{
    width: calc(80% - 2rem);
    height: 2rem;
    display: flex;
    flex-direction: row;
    padding: .4rem 1rem;
    margin-top: 5%;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    border: 0;

}
.selection{
    width: 80%!important;
    height: 2.8rem!important;
    display: flex!important;
    padding: .9rem 1rem!important;
    margin-top: 5%!important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: flex-start!important;
    border-radius: 8px!important;
    border: 0!important;

}
.dateselector{
    width: calc(80% - 2rem);
    height: 1.1rem;
    display: flex;
    padding: .4rem 1rem;
    margin-top: 5%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    border: 0;

}
.RegisterMain2 .RegisterFormInput:focus{
    border: none;
    outline: none;
    border-inline: none;
}
.RegisterMain2 .RegisterFormButton{
    width: 80%;
    height: 2rem;
    padding: 1.5rem 0;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    background-color: #ad1ddb;
    color: white;
    border-radius: 8px;
    border: 0;
}
.RegisterMain2 .RegisterFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    font-size: .9rem;
    font-weight: 600;
    color: #ffffff;
}
.RegisterMain2 .RegisterFooterLink{
    color: #0078FF;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RememberMe{
    width: 80%;
    padding-top: 5%;
    display: flex;
    justify-content: flex-start;
}
.RememberMeInput{
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}
.RegisterMain2 .RegisterHeaderResponse{
    width: calc(80% - 2rem);
    height: 4.5%;
    border-radius: 5px;
    display: none;
    justify-content: flex-start;
    padding: 3% 1rem;
    align-items: center;
    font-size: .7rem;
    font-weight: 600;
    background-color: #FF0000;
    color: #ffffff;
    transition: all .5s;
}
.RegisterMain2 .LoginBackground{
    position: absolute;
    z-index: 500;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: .5;
    display: none;
}
.RegisterMain2 .RegisterHeaderResponseActive{
    display: flex;
    transition: all .5s;
}
@media (min-width: 1024px){
    .mainnav{
        position: relative;
        z-index: 499;
    }
    .RegisterMain2 .LoginBackground{
        display: flex;
        z-index: 501;
        position: absolute;
        margin-top: -8vh;
    }
    .RegisterMain2 .RegisterPage{
        height: 100vh;
        width: 99.5vw;
        position: fixed;
        background-color: #ad1ddb!important;
    }
    .RegisterMain2{
        height: 95%;
        width: 30%;
        border-radius: 15px;
        margin-top: -2.5vh;
    }
    .RegisterMain2 .RegisterHeader{
        height: 10%;
        font-size: 1rem;
    }
    .RegisterMain2 .RegisterHeaderLogo{
        height: 30%;
        margin-left: 7.5%;
    }
    .RegisterMain2 .RegisterHeaderTitle{
        height: 5%;
        font-size: 1.4vw;
    }
    .RegisterMain2 .RegisterHeaderClose{
        height: 100%;
        align-items: flex-end;
    }
    .RegisterMain2 .RegisterHeaderLine{
        height: 6px;
    }
    .RegisterMain2 .RegisterHeaderRight{
        font-size: 5vw;
        margin-right: 7.5%;
    }
    .RegisterMain2 .RegisterHeaderRegister{
        font-size: 1rem;
    }
    .RegisterMain2 .RegisterContent{
        padding: 0 7.5%;
        width: 85%;
        height: 88%;
        align-items: flex-start;
    }
    .RegisterMain2 .RegisterHeaderTitle{
        padding: 2.5% 0;
        margin-left: 0;
        width: 100%;
    }
    .RegisterMain2 .RegisterHeaderResponse{
        margin-left:0;
        width: 90%;
        padding: 3.5% 5%;
        font-size: 1.1rem;
        height: unset;
    }
    .RegisterMain2 .RegisterForm{
        height: 80%;
        padding-bottom: 5%;
    }
    .RegisterMain2 .RegisterFooter{
        font-size: 1rem;
        margin: 4% 0;
    }
    .RegisterMain2 .RegisterFormInput{
        width: calc(100% - 2rem);
        height: 12%;
        margin-top: 3.5%;
    }
    .RegisterMain2 .selection{
        width: 100%!important;
        height: 11%!important;
        padding: 4% 1rem!important;
        margin-top: 3.5%!important;
    }
    .RememberMe{
        padding-top: 5%;
        width: 100%;
    }
    .RegisterMain2 .RegisterFormButton{
        width: 100%;
        margin-top: 3.5%;
        padding: 6% 0;
    }
}
.RegisterPage{
    background-color: #460073;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 92vh;
    position: relative;
    z-index: 500;
}
.RegisterMain1{
    display: flex;
    flex-direction: column;
    position: absolute!important;
    z-index: 501;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-top: -8vh;
    background-color: #460073;
}
.RegisterMain1 .RegisterHeader{
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8%;
    font-size: .7rem;
    font-weight: 600;
}
.RegisterMain1 .RegisterHeaderLogo{
    width: auto;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;

}
.RegisterMain1 .RegisterHeaderTitle{
    margin: 0;
    margin-left: 10%;
    width: 90%;
    height: 4%;
    font-size: 1.2rem;
    padding: 0;
    display: flex;
    justify-content: flex;
    align-items: center;
}
.RegisterMain1 .RegisterHeaderClose{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.RegisterMain1 .RegisterHeaderLine{
    width: 60%;
    height: 4px;
    background-color: #ffffff;
    position: absolute
}
.RegisterMain1 .RegisterHeaderRight{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5%;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    text-decoration: none;
    border-radius: 15px;
}
.RegisterMain1 .RegisterHeaderRegister{
    width: 40%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    font-size: .7rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #ad1ddb;
    text-decoration: none;
    border-radius: 15px;
}
.line1{
    transform: rotate(45deg);
}
.line2{
    transform: rotate(-45deg);
}
.RegisterMain1 .RegisterContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 92%;
}
.RegisterMain1 .RegisterForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}
.RegisterMain1 .RegisterFormInput{
    width: calc(80% - 2rem);
    height: 2rem;
    display: flex;
    padding: .4rem 1rem;
    margin-top: 5%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
}
.RegisterMain1 .RegisterFormInput:focus{
    border: none;
    outline: none;
    border-inline: none;
}
.RegisterMain1 .RegisterFormButton{
    width: 80%;
    height: 2rem;
    padding: 1.5rem 0;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    background-color: #ad1ddb;
    color: white;
    border-radius: 8px;
    border: 0;
}
.RegisterMain1 .RegisterFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    font-size: .9rem;
    font-weight: 600;
    color: #ffffff;
}
.RegisterMain1 .RegisterFooterLink{
    color: #0078FF;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RegisterMain1 .RegisterHeaderResponse{
    width: calc(80% - 2rem);
    height: 4.5%;
    border-radius: 5px;
    display: none;
    justify-content: flex-start;
    padding: 3% 1rem;
    align-items: center;
    font-size: .7rem;
    font-weight: 600;
    background-color: #FF0000;
    color: #ffffff;
    transition: all .5s;
}
.RegisterMain1 .LoginBackground{
    position: absolute;
    z-index: 500;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: .5;
    display: none;
}
.RegisterMain1 .RegisterHeaderResponseActive{
    display: flex;
    transition: all .5s;
}
@media (min-width: 1024px){
    .maincontainer{
        max-height: 100%;
    }
    .RegisterMain1 .LoginBackground{
        display: flex;
        z-index: 501;
        position: absolute;
        margin-top: -9vh;
    }
    .RegisterPage{
        height: 91vh!important;
        width: 99.5vw!important;
        position: fixed;
    }
    .RegisterMain1{
        height: 80%;
        width: 30%;
        border-radius: 15px;
        margin-top: 4.5vh;
    }
    .RegisterMain1 .RegisterHeader{
        height: 15%;
        font-size: 1rem;
    }
    .RegisterMain1 .RegisterHeaderLogo{
        height: 30%;
        margin-left: 7.5%;
    }
    .RegisterMain1 .RegisterHeaderTitle{
        height: 5%;
        font-size: 1.4vw;
    }
    .RegisterMain1 .RegisterHeaderClose{
        height: 100%;
        align-items: flex-end;
    }
    .RegisterMain1 .RegisterHeaderLine{
        height: 6px;
    }
    .RegisterMain1 .RegisterHeaderRight{
        font-size: 5vw;
        margin-right: 7.5%;
    }
    .RegisterMain1 .RegisterHeaderRegister{
        font-size: 1rem;
    }
    .RegisterMain1 .RegisterContent{
        padding: 0 7.5%;
        width: 85%;
        height: 85%;
        align-items: flex-start;
    }
    .RegisterMain1 .RegisterHeaderTitle{
        padding: 2.5% 0;
        margin-left: 0;
        width: 100%;
    }
    .RegisterMain1 .RegisterHeaderResponse{
        margin-left:0;
        width: 90%;
        padding: 3.5% 5%;
        font-size: 1.1rem;
    }
    .RegisterMain1 .RegisterForm{
        height: 75%;
        padding-bottom: 5%;
    }
    .RegisterMain1 .RegisterFooter{
        font-size: 1rem;
        margin: 4% 0;
    }
    .RegisterMain1 .RegisterFormInput{
        width: calc(100% - 2rem);
        height: 12%;
        margin-top: 3.5%;
    }
    .RegisterMain1 .RegisterFormButton{
        width: 100%;
        margin-top: 3.5%;
        padding: 6% 0;
    }
}
.profilemain {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.profilemain .container {
    padding: 0 5%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 4rem;
    background-color: #1c002e;
    position: relative;
}
.profilemain .container-error {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0%;
    z-index: 600;
}
.profilemain .container-error h1 {
    font-size: 3vw;
    padding: 5% 7%;
    text-align: left;
    font-weight: 600;
    color: #ffffff;
}
.profilemain .container-title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    color: #0078ff;
}
.profile {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.profile-title {
    width: 100%;
    padding: 10px 0;
    color: #ffffff;
    text-align: left;
}
.profile-title h1 {
    font-size: 18px;
    font-weight: 600;
}
.profile-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.profilemain .container-title{
    color:white!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.profile-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
    padding-top: 0;
}
.profile-input label {
    color: #ffffff;
}
.profile-input-disabled label {
    color: #acacac;
}
.profile-input input {
    height: 18px;
    font-size: 1rem;
    width: calc(100% - 20px);
    margin: 5px 0;
    padding: 10px 10px;
    border-radius: 5px;
    background-color: white;
    color: black;
    border: 0;
}
.profile-birthday {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0;
}
.birthday-selection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.profile-input select {
    height: 40px;
    font-size: 1rem;
    width: 100%;
    margin: 5px 0;
    padding: 10px 10px;
    border-radius: 5px;
    border: 0;
}
.birthday-selection select {
    width: 32%;
}
.profile-input-disabled input {
    background-color: #bababa;
    color: #3a3835;
}
.profile-end {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}
.profile-line {
    width: 100%;
    height: 1px;
    background-color: rgba(190, 167, 130, 0.3);
}
.profile-phone {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.profile-phone .area-code {
    width: 29%;
}
.profile-phone .phone-number {
    width: 69%;
}
.profile-address {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
}
.profile-address .profile-input {
    width: 49%;
}

.profile-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}
.profile-submit-button {
    background-color: #ad1ddb;
    color: white;
    border: 0;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 30px;
}
/* on desktop and laptop */
@media (min-width: 1024px) {
    .profilemain .container {
        padding: 0 10%;
        align-items: flex-start;
    }
    .profilemain .container-title {
        text-align: left;
    }
    .profile-content {
        /* diplay grid and 2 columns */
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
    }
    .profile-address {
        width: 200%;
    }
    .profile-nowpassword {
        /* width 2 columns */
        grid-column: 1 / 3;
    }
    .profilemain .container-error {
        top: 9vh!important;
        width: 30%!important;
        left: 35%!important;
        border-radius: 5px;
    }
    .profilemain .container-error-content {
        font-size: 1vw!important;
        padding: 1% 7%!important;
        text-align: left!important;
    }
}
/* on laptop or desktop */
@media (min-width: 768px) {
    .row{
        width: 100%;

    }
    .table-responsive{
        width: 100%!important;
        height: 100%!important;
        min-height: 100%!important;
        display: grid!important;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 5%;
    }
    .tablocan{
        width: 100%!important;
        height: 100%!important;
        align-items: center!important;
        flex-direction: column!important;
        
    }
    .card-img{
        width: 80%!important;
        height: auto!important;
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
    }
    .card-info{
        width: 100%!important;
        min-height: 20%!important;
        height: 100%!important;      
        align-items: center!important;
    }
    .card-info h4{
        font-size: 1.5rem!important;

    }
    .card-info p{
        font-size: 1rem!important;
    }
}
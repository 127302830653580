.CasinoMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 1em;
  padding: 10px 0;
  border-radius: 10px;
}
.hiddenGameInfos {
  display: none;
}
.CasinoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
}
.CasinoHeader h3 {
  display: none;
}
.CasinoSearch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: #fff;
  width: 42.5%;
  padding: 10px 10px 10px 0;
  border-radius: 10px;
}
.CasinoSearch span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  background-color: #fff;
  border-radius: 10px;
}
.CasinoSearch span img {
  width: 50%;
  height: auto;
}
.CasinoSearch input {
  width: 70%;
  height: calc(2rem - 40px);
  padding: 20px;
  margin: 0;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  color: rgb(0, 0, 0);
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(61, 61, 61);
}
.CasinoSearch input::placeholder {
  color: rgb(105, 105, 105);
}
.CasinoSearch input:focus {
  outline: none;
}
.ProviderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  border-radius: 10px;
  color: black;
  font-size: 0.95em;
}
.ProviderList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
  background-color: #ad1ddb;
}
.ProviderList ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
}
.ProviderIcon {
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 12.5%;
  border-radius: 10px;
  padding: 12.5px;
  z-index: 20;
}
.ProviderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 85%;
  border-radius: 10px;
  padding-right: 20px;
  z-index: 20;
}
.openedUl {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 25rem;
  width: 90%;
  padding: 0;
  border-radius: 10px;
}
.ProviderLister {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  position: absolute !important;
  justify-items: center !important;
  align-items: center !important;
  height: 100%;
  width: 100% !important;
  transform: translateY(55%) !important;
  padding: 0 !important;
  background-color: #282828 !important;
  color: #fff !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
.openedUl li {
  width: 90% !important;
  height: 70% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 10px;
  background-color: white;
  color: black;
}
.ProviderActive {
  background-color: #0078ff !important;
}
.CasinoContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  width: 100%;
  padding: 2rem 0;
  border-radius: 10px;
  margin-bottom: 3rem;
}
.CasinoGames {
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}
.CasinoGame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}
.CasinoGame img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 1024px) {
  .CasinoMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 1em;
    padding: 10px 0;
    border-radius: 10px;
  }
  .CasinoHeader {
    height: 1.8rem;
    justify-content: center;
  }
  .CasinoSearch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: #fff;
    width: 15%;
    margin: 0 0.5rem;
    padding: 10px 10px 10px 0;
    border-radius: 10px;
  }
  .ProviderMain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 15%;
    margin: 0 0.5rem;
    border-radius: 10px;
    color: black;
    font-size: 0.95em;
  }
  .ProviderUl {
    display: none !important;
  }
  .openedUl {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 13.5%;
    border-radius: 10px;
  }
  .ProviderLister {
    display: flex !important;
    flex-direction: column;
    transform: translateY(56.5%) !important;
    padding: 0 !important;
    z-index: 51;
  }
  .ProviderList ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .ProviderList ul li {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    border-radius: 10px !important;
    background-color: #282828 !important;
    color: #fff;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    cursor: pointer !important;
  }
  .ProviderOption {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: end;
  }
  .ProviderActive {
    color: #0078ff !important;
  }
  .CasinoContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 2.5% 0;
    border-radius: 10px;
    margin-bottom: 3rem;
  }
  .CasinoGames {
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .CasinoGame {
    position: relative;
  }
  .hiddenGameInfos {
    display: none;
    width: 100%;
    height: calc(100% - 0.5rem);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hiddenGameInfos h3 {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .hiddenGameInfos p {
    color: white;
    width: 90%;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .CasinoGame:hover .hiddenGameInfos {
    display: flex;
  }
  .hiddenGameInfos:hover {
    cursor: pointer;
  }
  .playBTN {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ad1ddb;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    width: 30%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

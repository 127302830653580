.container-fluid {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    background-color: #1c002e;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-title {
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    background-color: #ad1ddb;
    align-items: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.container-title h1 {
    font-size: 1.1em;
    color: #fff;
    text-align: center;
    width: 100%;
}
.container{
    width: 85%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.table-responsive{
    margin-top: 5%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 10% 0;
}
.card-img {
    width: 50%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.tablocan{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 2% 0;
}
.card-info{
    width: 45%;
    margin-left: 5%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.card-info h4{
    color: #fff;
    font-size: 1em;
    margin: 0%;
    font-weight: lighter;
}
.card-info p{
    color: #ffd91d;
    font-size: 1em;
    margin: 0%;
    font-weight: lighter;
    letter-spacing: .05em;
}
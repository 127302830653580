.LastWinnersMain{
    background-color: #1c002e;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 162px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
}
.LastWinnersTitle{
    font-size: 1.25em;
    font-weight: 700;
    margin: 10px 0px 0px 0px;
    color: #fff;
    font-weight: lighter;
    height: 15%;
}
.LastWinnersSlider{
    background-color: #1c002e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90vw;
    height: 55%;
    white-space: nowrap;
    transition: ease 1000ms;
}
.LastWinnersCard{
    margin-top: 20px;
    display: flex!important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
    height: 100%;
    background-color: #1c002e;
}
.bigslider{
    width: 90vw;
    display: inline-block;
    height: 100%;
    width: 100%;
}
.LastWinnersCardImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 15px 0 15px;
}
.LastWinnersCardImg img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 20%;
}
.LastWinnersCardInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 20%;
    height: 100%;
}
.LastWinnersCardInfo h4{
    font-size: 1em;
    font-weight: lighter;
    margin: 0;
    color: #fff;
}
.LastWinnersCardInfo p{
    font-size: .9em;
    font-weight: bolder;
    margin: 0;
    color: #fff;
}
.LastWinnersCardPrize{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    justify-content: center;
    width: 45%;
    height: 100%;
    font-size: 1.4em;
    font-weight: bolder;
    color: #fff;
}
.LastWinnersLoading{
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LastWinnersLoading img{
    width: auto;
    height: 30%;
}
@media (min-width: 768px){
    .LastWinnersMain{
        height: 200px;
    }
    .LastWinnersTitle{
        font-size: 1.5em;
    }
    .LastWinnersSlider{
        height: 60%;
        width: 100vw;
    }
    .LastWinnersCard{
        height: 100%;
        width: calc(30vw - 10px);
    }
    .LastWinnersCardImg{
        width: 60px;
        height: 60px;
        margin: 0 20px 0 20px;      
    }
    .LastWinnersCardInfo{
        width: 20%;
    }
    .LastWinnersCardInfo h4{
        font-size: 1.2em;
    }
    .LastWinnersCardInfo p{
        font-size: 1em;
    }
    .LastWinnersCardPrize{
        font-size: 1.4vw;
    }
    .bigslider{
        padding-right:10px;
    }
}